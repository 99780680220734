import '@gohighlevel/ghl-ui/dist/style.css'
import { initializeApp } from '@gohighlevel/clientportal-core'
import '@gohighlevel/clientportal-core/dist/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  replaceBucketUrlWithCdnUrl,
  fetchCurrencySymbol,
  truncateText,
} from '../src/helper/filter'
import '@/assets/css/tailwind.css'
import { createMetaManager } from 'vue-meta'
import VueCookies from 'vue-cookies'
import firebase from 'firebase/app'
import config from './config'
import { fetchCat } from '@/helper/permission.helper'
import mitt from 'mitt'
import { plugin as vueMetaPlugin } from 'vue-meta'
import { setupSentry } from './plugins/setupSentry'
import VueGtag from 'vue-gtag'
;(async () => {
  await initializeApp(process.env.NODE_ENV, router)
})()

const emitter = mitt()
firebase.initializeApp(config.firebase)

const app = createApp(App)

if (config.mode === 'production') setupSentry(app, router, config)

app
  .use(router)
  .use(store)
  .use(VueCookies)
  .use(createMetaManager())
  .use(vueMetaPlugin)
  .use(VueGtag, { bootstrap: false }, router)

app.config.globalProperties.emitter = emitter
app.config.globalProperties.replaceBucketUrlWithCdnUrl =
  replaceBucketUrlWithCdnUrl
app.config.globalProperties.fetchCurrencySymbol = fetchCurrencySymbol
app.config.globalProperties.truncateText = truncateText
app.mount('#app')

// Vue.config.productionTip = false

declare const ga: any
if (config.googleAnalyticId) {
  ga('create', config.googleAnalyticId, 'auto')
  router.afterEach((to, from) => {
    const cat = fetchCat()
    if (to.name !== from.name) {
      ga('set', 'page', to.name)
      ga('send', 'pageview')
      if (cat) {
        ga(
          'send',
          'event',
          'pageview',
          to.name,
          cat.locationId || cat.location_id,
          1
        )
      }
    }
  })
}

if (
  'serviceWorker' in navigator &&
  !window.location.pathname?.startsWith('/courses')
) {
  navigator.serviceWorker
    .register('/membership-sw.js', { scope: '.' })
    .then((registration) => {
      console.log(registration)
    })
    .catch(function (error) {
      console.error('Failed to register Service Worker:', error)
    })
}
