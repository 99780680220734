<template>
  <div id="app">
    <metainfo></metainfo>
    <div
      v-if="fetching"
      class="w-full h-screen flex items-center justify-center"
    >
      <UIContentWrap :fullScreen="true">
        <UISpinner size="extra-large" />
      </UIContentWrap>
    </div>
    <UIContentWrap class="route-container" v-else :fullScreen="true">
      <router-view @updateLoginStatus="checkLoginStatus" />
    </UIContentWrap>
    <MobileFloatingNavigation v-if="newMobileScreens" />
    <MobileAlertModal
      v-if="newMobileScreens && isLoggedIn && !isClientPortal() && localPWAFlag"
      :pwa-alert-data="pwaAppSettings"
      :isLoggedIn="isLoggedIn"
    />
    <PaymentRedirectionConfirmation v-if="!fetching" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import MobileFloatingNavigation from '@/components/common/MobileFloatingNavigation.vue'
import {
  fetchAndSaveBrandData,
  getMobileTheme,
  isClientPortal,
  sanitizeJavaScriptCode,
  createElement,
  detectDevice,
  isAppInstalled,
} from './helper'
import { isPwa } from '@gohighlevel/clientportal-core'
import { fetchCat } from '@/helper/permission.helper'
import MobileAlertModal from '@/components/common/MobileAlertModal.vue'
import UISpinner from '@/components/common/UISpinner.vue'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import postscribe from 'postscribe'
import config from './config'
import { generateToken } from '../http-common'
import restAgent from './helper/restAgent'
import 'firebase/auth'
import http from '../http-common'
import PaymentRedirectionConfirmation from '@/components/checkout/PaymentRedirectionConfirmation.vue'
const route = useRoute()

export default defineComponent({
  metaInfo() {
    const meta = {
      title: this.$store.getters.pageTitle,
      link: [
        {
          vmid: 'favicon',
          rel: 'shortcut icon',
          href: this.$store.getters.favicon,
        },
      ],
    }

    if (this.appManifest) {
      meta['link'].push(this.appManifest)
      meta['link'].push(...this.appleIcons)
      meta['name'] = this.appleMeta
    }
    if (this.newMobileScreens) {
      meta['meta'] = [this.zoomDisableMeta]
    }

    return meta
  },
  components: {
    MobileFloatingNavigation,
    MobileAlertModal,
    UISpinner,
    UIContentWrap,
    PaymentRedirectionConfirmation,
  },
  beforeMount() {
    this.updateDevice()
    window.addEventListener('resize', this.updateDevice)
    this.checkLoginStatus()
  },
  async created() {
    await this.reIssueTokenWithExternalId()
  },
  unmounted() {
    this.emitter.off('handleUpsellCheckout')
  },
  async mounted() {
    const activeCoursesRoute = this.$route.query.courses
      ? this.$route.query.courses === 'all'
        ? 'All Courses'
        : 'My Courses'
      : 'All Courses'
    this.$store.commit('UPDATE_ACTIVE_LIBRARY', activeCoursesRoute)
    this.emitter.on('handleUpsellCheckout', this.handleUpsellCheckout)
    this.fetching = true
    try {
      if (isClientPortal()) {
        await this.getUserDataFromClientPortal()
      }
      const brandData = await fetchAndSaveBrandData(this)
      if (!brandData) return
      // fetch portal settings data if not the cat
      const { siteInfo, builderSettings } = brandData

      if (siteInfo) {
        const { customJs, customCss, customHeader, customFooter } = siteInfo

        this.siteCustomJs = customJs || ''
        this.siteCustomCss = customCss || ''
        this.siteCustomHeader = customHeader || ''
        this.siteCustomFooter = customFooter || ''

        if (!this.locationId)
          this.$store.commit('SAVE_LOCATION_ID', siteInfo.locationId)
      }

      if (builderSettings) {
        const { appSettings, pwaEnabled } = builderSettings
        // making PWA as default
        const defaultPWAEnabled = true
        if (!isClientPortal()) {
          this.manifestData = appSettings || null
        }
        if (this.manifestData && pwaEnabled) {
          this.manifestData.start_url =
            config.mode === 'dev'
              ? 'http://localhost:4040/'
              : `https://${window.location.hostname}/`
          this.manifestData.display = 'standalone'
          this.manifestData.offline_enabled = true
        }

        if (appSettings) {
          this.setPWAAppSettings({
            name: appSettings.name,
            logo:
              appSettings.icons && appSettings.icons.length > 0
                ? appSettings.icons[0].src
                : '',
            description: appSettings.description,
            pwaEnabled: pwaEnabled, // keeping this flag if the PWA is not configured
          })
        }

        this.localPWAFlag = pwaEnabled
        this.updateMobileScreens(defaultPWAEnabled) //pwaEnabled
      }

      this.setAppFlags() // setting app flags once brand data is fetched

      // Below code will add custom code in Web and Legacy courses which is coming from courses
      if (this.isCoursesCustomCodeExecutable) {
        this.addCoursesCustomCode()
      }
      // Below code will add custom code in Web which is coming from client portal
      if (this.isClientPortalWeb) {
        this.addClientPortalCustomCode()
      }
    } catch (error) {
      console.error('Error while fetching brand data --> ', error)
    } finally {
      this.fetching = false
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDevice)
    this.removePWAEvent()
  },
  methods: {
    async getUserDataFromClientPortal() {
      this.fetchingClientPortal = true
      const cat = fetchCat()
      const token = await generateToken()
      const contactId = cat && cat.contactId ? cat.contactId : cat?.contact_id
      const locationId =
        cat && cat.locationId ? cat.locationId : cat?.location_id
      try {
        if (locationId && contactId) {
          const data = await restAgent.ClientPortal.getUserByContactId(
            locationId,
            contactId
          )
          this.$store.commit('SET_CLIENT_PORTAL_USER_DATA', data)
          this.$store.commit('SAVE_LOCATION_ID', locationId)
        }
      } catch (e) {
        console.error('Error while fetching client portal data: ', e)
      } finally {
        this.fetchingClientPortal = false
      }
    },
    addCoursesCustomCode() {
      this.addCustomCSSCode()
      this.addCustomJsCode()
      this.addCustomHeaderAndFooterCode()
    },
    addCustomHeaderAndFooterCode() {
      if (this.siteCustomHeader) {
        postscribe(`#siteCustomHeader`, this.siteCustomHeader)
      }

      if (this.siteCustomFooter) {
        postscribe(`#siteCustomFooter`, this.siteCustomFooter)
      }
    },
    addClientPortalCustomCode() {
      const cpCustomJs = createElement(
        'script',
        [
          { type: 'type', value: 'text/javascript' },
          { type: 'vmid', value: 'client-portal-custom-js' },
        ],
        sanitizeJavaScriptCode(this.clientPortalData?.customJs)
      )

      const cpCustomCss = createElement(
        'style',
        [
          { type: 'type', value: 'text/css' },
          { type: 'vmid', value: 'client-portal-custom-css' },
        ],
        this.clientPortalData?.customCss
      )

      document.head.appendChild(cpCustomJs)
      document.head.appendChild(cpCustomCss)
      if (this.clientPortalData?.headerCode) {
        postscribe(
          '#clientPortalSiteCustomHeader',
          this.clientPortalData?.headerCode
        )
      }

      if (this.clientPortalData?.footerCode) {
        postscribe(
          '#clientPortalSiteCustomFooter',
          this.clientPortalData?.footerCode
        )
      }
    },
    addCustomCSSCode() {
      const customCss = document.createElement('style')
      customCss.setAttribute('type', 'text/css')
      customCss.setAttribute('vmid', 'site-custom-css')
      customCss.innerHTML = this.siteCustomCss
      document.head.appendChild(customCss)
    },
    addCustomJsCode() {
      const customScript = document.createElement('script')
      customScript.setAttribute('type', 'text/javascript')
      customScript.setAttribute('vmid', 'site-custom-js')
      customScript.innerHTML = sanitizeJavaScriptCode(this.siteCustomJs)
      document.head.appendChild(customScript)
    },
    handleUpsellCheckout(upsellId: string) {
      const routeData = {
        name: isClientPortal() ? 'in-app-upsell-v2' : 'in-app-upsell',
        params: {
          id: upsellId,
        },
      }
      if (!this.newMobileScreens) {
        const routerData = this.$router.resolve(routeData)
        const openedWindow = window.open(routerData.href, '_blank')
        const timer = setInterval(async () => {
          if (openedWindow.closed) {
            clearInterval(timer)
            window.location.reload()
          }
        }, 500)
      } else {
        this.$router.push(routeData)
      }
    },
    updateDevice() {
      if (window.innerWidth <= 1024) {
        this.$store.commit('SET_DEVICE', 'mobile')
      } else {
        this.$store.commit('SET_DEVICE', 'desktop')
      }

      this.updateMobileScreens(this.localPWAFlag)
    },
    updateMobileScreens(pwaFlag) {
      this.$store.commit(
        'SHOW_NEW_MOBILE_SCREENS',
        this.device === 'mobile' && !this.isBuilder // ? pwaFlag : false
      )
    },
    setPWAAppSettings(settings) {
      this.$store.commit('SET_PWA_APP_SETTINGS', settings)
    },
    removePWAEvent() {
      this.$store.commit('SET_PWA_APP_SETTINGS', {
        addWithExisting: true,
        pwaEvent: null,
      })
    },
    checkLoginStatus() {
      const cat = fetchCat()
      this.isLoggedIn = cat && (cat.contact_id || cat.contactId) ? true : false
    },
    getMobileTheme,
    isClientPortal,
    async reIssueTokenWithExternalId() {
      // fetch new token with external user id to let user access the membership portal
      const cat = await fetchCat()
      if (cat?.externalUserId || !isClientPortal()) {
        return
      }

      const response = await http.post(
        `${config.clientPortalServerUrl}/auth/regenerate-token`,
        {},
        {
          headers: {
            'Token-Id': cat.tokenId, // Set 'Token-id' with the extracted token value
          },
        }
      )
      const firebaseToken = response.data

      window.$cookies.remove('cat')
      window.$cookies.set('cat', window.btoa(JSON.stringify(firebaseToken)))
      location.reload()
      await generateToken()
    },
    setAppFlags() {
      this.$store.commit('SET_APP_FLAGS', {
        isLegacyWeb: !this.newMobileScreens && !isClientPortal(),
        isLegacyMobileBrowser: this.newMobileScreens && !isClientPortal(),
        isLegacyPWA:
          this.newMobileScreens && !isClientPortal() && isAppInstalled(),
        isClientPortalWeb: !this.newMobileScreens && isClientPortal(),
        isClientPortalMobileBrowser: this.newMobileScreens && isClientPortal(),
        isClientPortalMobileIOS:
          this.newMobileScreens &&
          isClientPortal() &&
          detectDevice() === 'iphone' &&
          isPwa(),
        isClientPortalMobileAndroid:
          this.newMobileScreens &&
          isClientPortal() &&
          detectDevice() === 'android' &&
          isPwa(),
      })
    },
  },
  data() {
    return {
      siteCustomJs: '',
      siteCustomCss: '',
      manifestData: null,
      localPWAFlag: false,
      fetching: false,
      isLoggedIn: false,
      fetchingClientPortal: false,
      siteCustomHeader: '',
      siteCustomFooter: '',
    }
  },
  computed: {
    appManifest(): any {
      if (this.manifestData) {
        const data = JSON.stringify(this.manifestData)
        const blob = new Blob([data], { type: 'application/json' })
        const manifestURL = URL.createObjectURL(blob)
        return {
          vmid: 'manifest',
          rel: 'manifest',
          href: manifestURL,
        }
      }
      return null
    },
    appleMeta(): any {
      return [
        {
          vmid: 'apple-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.manifestData.name,
        },
        {
          vmid: 'apple-web-app-capable',
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
      ]
    },
    appleIcons(): any {
      return this.manifestData.icons.map((icon, index) => {
        if (icon.src) {
          const appleIcon = {
            vmid: `apple-touch-icon-${index + 1}`,
            rel: 'apple-touch-icon',
            sizes: icon.sizes,
            href: icon.src,
          }
          return appleIcon
        }
      })
    },
    zoomDisableMeta() {
      return {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      }
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    pwaAppSettings() {
      return this.$store.getters.pwaAppSettings
    },
    locationId(): string {
      return this.$store.getters.locationId
    },
    clientPortalData() {
      return this.$store.getters.clientPortalDomainData
    },
    isCoursesCustomCodeExecutable() {
      return (
        this.$store.getters.appFlags.isLegacyMobileBrowser ||
        this.$store.getters.appFlags.isLegacyPWA ||
        this.$store.getters.appFlags.isLegacyWeb ||
        this.$store.getters.appFlags.isClientPortalWeb
      )
    },
    isClientPortalWeb() {
      return this.$store.getters.appFlags.isClientPortalWeb
    },
  },
})
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  overscroll-behavior: none;
}
/*::-webkit-scrollbar {
  width: 0px;
  -webkit-appearance: none;
}*/
.courses-font-family {
  font-family: Inter var, sans-serif;
}
.route-container {
  padding: 0px !important;
}
</style>
