import config from '@/config'

const CLOUD_ASSET_URL = `${config.firebase.assetsUrl}/assets/membership`

export const defaultLogo = `${CLOUD_ASSET_URL}/default-logo.svg`

export const defaultProductBackground = {
  Default: `${CLOUD_ASSET_URL}/default-product-background.jpg`,
  Classic: `${CLOUD_ASSET_URL}/default-product-background.jpg`,
  NewYork: `${CLOUD_ASSET_URL}/newyork-product-background.png`,
}

export const defaultPosterImage = `${CLOUD_ASSET_URL}/image-placeholder.svg`

export const defaultAssessmentImage = `${CLOUD_ASSET_URL}/assessment-thumbnail-newIllustrator.svg`

export const assessmentStartImage = `${CLOUD_ASSET_URL}/assessments-home.png`

export const assessmentCompleteImage = `${CLOUD_ASSET_URL}/Winner_Isometric.svg`

export const defaultVideoPlaceHolder = `${CLOUD_ASSET_URL}/upload_video_placeholder-47609acfa10d5195b8cb6dfb0d565c7cff899246aec0603343ac96a9115ad89d.png`

export const defaultBannerPlaceHolder = `${CLOUD_ASSET_URL}/default-banner-a806c42d610177067f62dad76bdaf048f5f4731486305bca7b39854cfcff6b21.png`

export const checkIcon = `${CLOUD_ASSET_URL}/checkmark.svg`

export const lockIcon = `${CLOUD_ASSET_URL}/lock.svg`

export const playBtnImage = `${CLOUD_ASSET_URL}/play-btn.svg`

export const pdfIcon = `${CLOUD_ASSET_URL}/pdf-icon.svg`

export const imageIcon = `${CLOUD_ASSET_URL}/image-icon.svg`

export const docIcon = `${CLOUD_ASSET_URL}/doc-icon.webp`

export const userAvatarImage = `${CLOUD_ASSET_URL}/user-avatar.svg`

export const productIcon = `${CLOUD_ASSET_URL}/product_ico.svg`

export const folderIcon = `${CLOUD_ASSET_URL}/folder_ico.svg`

export const fileIcon = `${CLOUD_ASSET_URL}/file_ico.svg`

export const CLOUD_ASSETS_URL = `${CLOUD_ASSET_URL}/membership-icons`

export const MEMBERSHIP_GTAGID = 'G-WWDNWPX8Z2'
export const CLIENTPORTAL_GTAGID = 'G-91CZG6XDY5'

export const iconPaths = {
  assessments: '/assessments.svg',
  book: '/book.svg',
  bulb: '/bulb.svg',
  cart: '/cart.svg',
  checkcircle: '/check_circle.svg',
  chevrondown: '/chevron_down.svg',
  chevronleft: '/chevron_left.svg',
  chevronright: '/chevron_right.svg',
  comment: '/comments.svg',
  copy: '/copy.svg',
  correct: '/correct.svg',
  cross: '/cross.svg',
  crosswithcircle: '/cross_with_circle.svg',
  docdownload: '/doc_download.svg',
  document: '/document.svg',
  dotsvertical: '/dots_vertical.svg',
  download: '/download.svg',
  exit: '/exit.svg',
  files: '/files.svg',
  fullwindow: '/full_window.svg',
  globealt: '/globe_alt.svg',
  hamburger: '/hamburger.svg',
  install: '/install.svg',
  iosshare: '/ios_share.svg',
  lockclosed: '/lock_closed.svg',
  lockfilled: '/lock_filled.svg',
  nextarrow: '/next_arrow.svg',
  paperairplane: '/paper_airplane.svg',
  play: '/play.svg',
  previousarrow: '/previous_arrow.svg',
  reply: '/reply.svg',
  scholar: '/scholar.svg',
  search: '/search.svg',
  trash: '/trash.svg',
  user: '/user.svg',
  usergroup: '/user_group.svg',
  userprofile: '/user_profile.svg',
}

export const currency: any = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'USD',
    namePlural: 'US dollars',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'CAD',
    namePlural: 'Canadian dollars',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbolNative: '€',
    decimalDigits: 2,
    rounding: 0,
    code: 'EUR',
    namePlural: 'euros',
  },
  AED: {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    symbolNative: 'د.إ.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'AED',
    namePlural: 'UAE dirhams',
  },
  AFN: {
    symbol: 'Af',
    name: 'Afghan Afghani',
    symbolNative: '؋',
    decimalDigits: 0,
    rounding: 0,
    code: 'AFN',
    namePlural: 'Afghan Afghanis',
  },
  ALL: {
    symbol: 'ALL',
    name: 'Albanian Lek',
    symbolNative: 'Lek',
    decimalDigits: 0,
    rounding: 0,
    code: 'ALL',
    namePlural: 'Albanian lekë',
  },
  AMD: {
    symbol: 'AMD',
    name: 'Armenian Dram',
    symbolNative: 'դր.',
    decimalDigits: 0,
    rounding: 0,
    code: 'AMD',
    namePlural: 'Armenian drams',
  },
  ARS: {
    symbol: 'AR$',
    name: 'Argentine Peso',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'ARS',
    namePlural: 'Argentine pesos',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'AUD',
    namePlural: 'Australian dollars',
  },
  AZN: {
    symbol: 'man.',
    name: 'Azerbaijani Manat',
    symbolNative: 'ман.',
    decimalDigits: 2,
    rounding: 0,
    code: 'AZN',
    namePlural: 'Azerbaijani manats',
  },
  BAM: {
    symbol: 'KM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbolNative: 'KM',
    decimalDigits: 2,
    rounding: 0,
    code: 'BAM',
    namePlural: 'Bosnia-Herzegovina convertible marks',
  },
  BDT: {
    symbol: 'Tk',
    name: 'Bangladeshi Taka',
    symbolNative: '৳',
    decimalDigits: 2,
    rounding: 0,
    code: 'BDT',
    namePlural: 'Bangladeshi takas',
  },
  BGN: {
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    symbolNative: 'лв.',
    decimalDigits: 2,
    rounding: 0,
    code: 'BGN',
    namePlural: 'Bulgarian leva',
  },
  BHD: {
    symbol: 'BD',
    name: 'Bahraini Dinar',
    symbolNative: 'د.ب.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'BHD',
    namePlural: 'Bahraini dinars',
  },
  BIF: {
    symbol: 'FBu',
    name: 'Burundian Franc',
    symbolNative: 'FBu',
    decimalDigits: 0,
    rounding: 0,
    code: 'BIF',
    namePlural: 'Burundian francs',
  },
  BND: {
    symbol: 'BN$',
    name: 'Brunei Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'BND',
    namePlural: 'Brunei dollars',
  },
  BOB: {
    symbol: 'Bs',
    name: 'Bolivian Boliviano',
    symbolNative: 'Bs',
    decimalDigits: 2,
    rounding: 0,
    code: 'BOB',
    namePlural: 'Bolivian bolivianos',
  },
  BRL: {
    symbol: 'R$',
    name: 'Brazilian Real',
    symbolNative: 'R$',
    decimalDigits: 2,
    rounding: 0,
    code: 'BRL',
    namePlural: 'Brazilian reals',
  },
  BWP: {
    symbol: 'BWP',
    name: 'Botswanan Pula',
    symbolNative: 'P',
    decimalDigits: 2,
    rounding: 0,
    code: 'BWP',
    namePlural: 'Botswanan pulas',
  },
  BYR: {
    symbol: 'BYR',
    name: 'Belarusian Ruble',
    symbolNative: 'BYR',
    decimalDigits: 0,
    rounding: 0,
    code: 'BYR',
    namePlural: 'Belarusian rubles',
  },
  BZD: {
    symbol: 'BZ$',
    name: 'Belize Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'BZD',
    namePlural: 'Belize dollars',
  },
  CDF: {
    symbol: 'CDF',
    name: 'Congolese Franc',
    symbolNative: 'FrCD',
    decimalDigits: 2,
    rounding: 0,
    code: 'CDF',
    namePlural: 'Congolese francs',
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
    symbolNative: 'CHF',
    decimalDigits: 2,
    rounding: 0.05,
    code: 'CHF',
    namePlural: 'Swiss francs',
  },
  CLP: {
    symbol: 'CL$',
    name: 'Chilean Peso',
    symbolNative: '$',
    decimalDigits: 0,
    rounding: 0,
    code: 'CLP',
    namePlural: 'Chilean pesos',
  },
  CNY: {
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    symbolNative: 'CN¥',
    decimalDigits: 2,
    rounding: 0,
    code: 'CNY',
    namePlural: 'Chinese yuan',
  },
  COP: {
    symbol: 'CO$',
    name: 'Colombian Peso',
    symbolNative: '$',
    decimalDigits: 0,
    rounding: 0,
    code: 'COP',
    namePlural: 'Colombian pesos',
  },
  CRC: {
    symbol: '₡',
    name: 'Costa Rican Colón',
    symbolNative: '₡',
    decimalDigits: 0,
    rounding: 0,
    code: 'CRC',
    namePlural: 'Costa Rican colóns',
  },
  CVE: {
    symbol: 'CV$',
    name: 'Cape Verdean Escudo',
    symbolNative: 'CV$',
    decimalDigits: 2,
    rounding: 0,
    code: 'CVE',
    namePlural: 'Cape Verdean escudos',
  },
  CZK: {
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    symbolNative: 'Kč',
    decimalDigits: 2,
    rounding: 0,
    code: 'CZK',
    namePlural: 'Czech Republic korunas',
  },
  DJF: {
    symbol: 'Fdj',
    name: 'Djiboutian Franc',
    symbolNative: 'Fdj',
    decimalDigits: 0,
    rounding: 0,
    code: 'DJF',
    namePlural: 'Djiboutian francs',
  },
  DKK: {
    symbol: 'Dkr',
    name: 'Danish Krone',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'DKK',
    namePlural: 'Danish kroner',
  },
  DOP: {
    symbol: 'RD$',
    name: 'Dominican Peso',
    symbolNative: 'RD$',
    decimalDigits: 2,
    rounding: 0,
    code: 'DOP',
    namePlural: 'Dominican pesos',
  },
  DZD: {
    symbol: 'DA',
    name: 'Algerian Dinar',
    symbolNative: 'د.ج.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'DZD',
    namePlural: 'Algerian dinars',
  },
  EEK: {
    symbol: 'Ekr',
    name: 'Estonian Kroon',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'EEK',
    namePlural: 'Estonian kroons',
  },
  EGP: {
    symbol: 'EGP',
    name: 'Egyptian Pound',
    symbolNative: 'ج.م.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'EGP',
    namePlural: 'Egyptian pounds',
  },
  ERN: {
    symbol: 'Nfk',
    name: 'Eritrean Nakfa',
    symbolNative: 'Nfk',
    decimalDigits: 2,
    rounding: 0,
    code: 'ERN',
    namePlural: 'Eritrean nakfas',
  },
  ETB: {
    symbol: 'Br',
    name: 'Ethiopian Birr',
    symbolNative: 'Br',
    decimalDigits: 2,
    rounding: 0,
    code: 'ETB',
    namePlural: 'Ethiopian birrs',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbolNative: '£',
    decimalDigits: 2,
    rounding: 0,
    code: 'GBP',
    namePlural: 'British pounds sterling',
  },
  GEL: {
    symbol: 'GEL',
    name: 'Georgian Lari',
    symbolNative: 'GEL',
    decimalDigits: 2,
    rounding: 0,
    code: 'GEL',
    namePlural: 'Georgian laris',
  },
  GHS: {
    symbol: 'GH₵',
    name: 'Ghanaian Cedi',
    symbolNative: 'GH₵',
    decimalDigits: 2,
    rounding: 0,
    code: 'GHS',
    namePlural: 'Ghanaian cedis',
  },
  GNF: {
    symbol: 'FG',
    name: 'Guinean Franc',
    symbolNative: 'FG',
    decimalDigits: 0,
    rounding: 0,
    code: 'GNF',
    namePlural: 'Guinean francs',
  },
  GTQ: {
    symbol: 'GTQ',
    name: 'Guatemalan Quetzal',
    symbolNative: 'Q',
    decimalDigits: 2,
    rounding: 0,
    code: 'GTQ',
    namePlural: 'Guatemalan quetzals',
  },
  HKD: {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'HKD',
    namePlural: 'Hong Kong dollars',
  },
  HNL: {
    symbol: 'HNL',
    name: 'Honduran Lempira',
    symbolNative: 'L',
    decimalDigits: 2,
    rounding: 0,
    code: 'HNL',
    namePlural: 'Honduran lempiras',
  },
  HRK: {
    symbol: 'kn',
    name: 'Croatian Kuna',
    symbolNative: 'kn',
    decimalDigits: 2,
    rounding: 0,
    code: 'HRK',
    namePlural: 'Croatian kunas',
  },
  HUF: {
    symbol: 'Ft',
    name: 'Hungarian Forint',
    symbolNative: 'Ft',
    decimalDigits: 0,
    rounding: 0,
    code: 'HUF',
    namePlural: 'Hungarian forints',
  },
  IDR: {
    symbol: 'Rp',
    name: 'Indonesian Rupiah',
    symbolNative: 'Rp',
    decimalDigits: 0,
    rounding: 0,
    code: 'IDR',
    namePlural: 'Indonesian rupiahs',
  },
  ILS: {
    symbol: '₪',
    name: 'Israeli New Sheqel',
    symbolNative: '₪',
    decimalDigits: 2,
    rounding: 0,
    code: 'ILS',
    namePlural: 'Israeli new sheqels',
  },
  INR: {
    symbol: 'Rs',
    name: 'Indian Rupee',
    symbolNative: 'টকা',
    decimalDigits: 2,
    rounding: 0,
    code: 'INR',
    namePlural: 'Indian rupees',
  },
  IQD: {
    symbol: 'IQD',
    name: 'Iraqi Dinar',
    symbolNative: 'د.ع.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'IQD',
    namePlural: 'Iraqi dinars',
  },
  IRR: {
    symbol: 'IRR',
    name: 'Iranian Rial',
    symbolNative: '﷼',
    decimalDigits: 0,
    rounding: 0,
    code: 'IRR',
    namePlural: 'Iranian rials',
  },
  ISK: {
    symbol: 'Ikr',
    name: 'Icelandic Króna',
    symbolNative: 'kr',
    decimalDigits: 0,
    rounding: 0,
    code: 'ISK',
    namePlural: 'Icelandic krónur',
  },
  JMD: {
    symbol: 'J$',
    name: 'Jamaican Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'JMD',
    namePlural: 'Jamaican dollars',
  },
  JOD: {
    symbol: 'JD',
    name: 'Jordanian Dinar',
    symbolNative: 'د.أ.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'JOD',
    namePlural: 'Jordanian dinars',
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbolNative: '￥',
    decimalDigits: 0,
    rounding: 0,
    code: 'JPY',
    namePlural: 'Japanese yen',
  },
  KES: {
    symbol: 'Ksh',
    name: 'Kenyan Shilling',
    symbolNative: 'Ksh',
    decimalDigits: 2,
    rounding: 0,
    code: 'KES',
    namePlural: 'Kenyan shillings',
  },
  KHR: {
    symbol: 'KHR',
    name: 'Cambodian Riel',
    symbolNative: '៛',
    decimalDigits: 2,
    rounding: 0,
    code: 'KHR',
    namePlural: 'Cambodian riels',
  },
  KMF: {
    symbol: 'CF',
    name: 'Comorian Franc',
    symbolNative: 'FC',
    decimalDigits: 0,
    rounding: 0,
    code: 'KMF',
    namePlural: 'Comorian francs',
  },
  KRW: {
    symbol: '₩',
    name: 'South Korean Won',
    symbolNative: '₩',
    decimalDigits: 0,
    rounding: 0,
    code: 'KRW',
    namePlural: 'South Korean won',
  },
  KWD: {
    symbol: 'KD',
    name: 'Kuwaiti Dinar',
    symbolNative: 'د.ك.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'KWD',
    namePlural: 'Kuwaiti dinars',
  },
  KZT: {
    symbol: 'KZT',
    name: 'Kazakhstani Tenge',
    symbolNative: 'тңг.',
    decimalDigits: 2,
    rounding: 0,
    code: 'KZT',
    namePlural: 'Kazakhstani tenges',
  },
  LBP: {
    symbol: 'LB£',
    name: 'Lebanese Pound',
    symbolNative: 'ل.ل.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'LBP',
    namePlural: 'Lebanese pounds',
  },
  LKR: {
    symbol: 'SLRs',
    name: 'Sri Lankan Rupee',
    symbolNative: 'SL Re',
    decimalDigits: 2,
    rounding: 0,
    code: 'LKR',
    namePlural: 'Sri Lankan rupees',
  },
  LTL: {
    symbol: 'Lt',
    name: 'Lithuanian Litas',
    symbolNative: 'Lt',
    decimalDigits: 2,
    rounding: 0,
    code: 'LTL',
    namePlural: 'Lithuanian litai',
  },
  LVL: {
    symbol: 'Ls',
    name: 'Latvian Lats',
    symbolNative: 'Ls',
    decimalDigits: 2,
    rounding: 0,
    code: 'LVL',
    namePlural: 'Latvian lati',
  },
  LYD: {
    symbol: 'LD',
    name: 'Libyan Dinar',
    symbolNative: 'د.ل.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'LYD',
    namePlural: 'Libyan dinars',
  },
  MAD: {
    symbol: 'MAD',
    name: 'Moroccan Dirham',
    symbolNative: 'د.م.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'MAD',
    namePlural: 'Moroccan dirhams',
  },
  MDL: {
    symbol: 'MDL',
    name: 'Moldovan Leu',
    symbolNative: 'MDL',
    decimalDigits: 2,
    rounding: 0,
    code: 'MDL',
    namePlural: 'Moldovan lei',
  },
  MGA: {
    symbol: 'MGA',
    name: 'Malagasy Ariary',
    symbolNative: 'MGA',
    decimalDigits: 0,
    rounding: 0,
    code: 'MGA',
    namePlural: 'Malagasy Ariaries',
  },
  MKD: {
    symbol: 'MKD',
    name: 'Macedonian Denar',
    symbolNative: 'MKD',
    decimalDigits: 2,
    rounding: 0,
    code: 'MKD',
    namePlural: 'Macedonian denari',
  },
  MMK: {
    symbol: 'MMK',
    name: 'Myanma Kyat',
    symbolNative: 'K',
    decimalDigits: 0,
    rounding: 0,
    code: 'MMK',
    namePlural: 'Myanma kyats',
  },
  MOP: {
    symbol: 'MOP$',
    name: 'Macanese Pataca',
    symbolNative: 'MOP$',
    decimalDigits: 2,
    rounding: 0,
    code: 'MOP',
    namePlural: 'Macanese patacas',
  },
  MUR: {
    symbol: 'MURs',
    name: 'Mauritian Rupee',
    symbolNative: 'MURs',
    decimalDigits: 0,
    rounding: 0,
    code: 'MUR',
    namePlural: 'Mauritian rupees',
  },
  MXN: {
    symbol: 'MX$',
    name: 'Mexican Peso',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'MXN',
    namePlural: 'Mexican pesos',
  },
  MYR: {
    symbol: 'RM',
    name: 'Malaysian Ringgit',
    symbolNative: 'RM',
    decimalDigits: 2,
    rounding: 0,
    code: 'MYR',
    namePlural: 'Malaysian ringgits',
  },
  MZN: {
    symbol: 'MTn',
    name: 'Mozambican Metical',
    symbolNative: 'MTn',
    decimalDigits: 2,
    rounding: 0,
    code: 'MZN',
    namePlural: 'Mozambican meticals',
  },
  NAD: {
    symbol: 'N$',
    name: 'Namibian Dollar',
    symbolNative: 'N$',
    decimalDigits: 2,
    rounding: 0,
    code: 'NAD',
    namePlural: 'Namibian dollars',
  },
  NGN: {
    symbol: '₦',
    name: 'Nigerian Naira',
    symbolNative: '₦',
    decimalDigits: 2,
    rounding: 0,
    code: 'NGN',
    namePlural: 'Nigerian nairas',
  },
  NIO: {
    symbol: 'C$',
    name: 'Nicaraguan Córdoba',
    symbolNative: 'C$',
    decimalDigits: 2,
    rounding: 0,
    code: 'NIO',
    namePlural: 'Nicaraguan córdobas',
  },
  NOK: {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'NOK',
    namePlural: 'Norwegian kroner',
  },
  NPR: {
    symbol: 'NPRs',
    name: 'Nepalese Rupee',
    symbolNative: 'नेरू',
    decimalDigits: 2,
    rounding: 0,
    code: 'NPR',
    namePlural: 'Nepalese rupees',
  },
  NZD: {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'NZD',
    namePlural: 'New Zealand dollars',
  },
  OMR: {
    symbol: 'OMR',
    name: 'Omani Rial',
    symbolNative: 'ر.ع.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'OMR',
    namePlural: 'Omani rials',
  },
  PAB: {
    symbol: 'B/.',
    name: 'Panamanian Balboa',
    symbolNative: 'B/.',
    decimalDigits: 2,
    rounding: 0,
    code: 'PAB',
    namePlural: 'Panamanian balboas',
  },
  PEN: {
    symbol: 'S/.',
    name: 'Peruvian Nuevo Sol',
    symbolNative: 'S/.',
    decimalDigits: 2,
    rounding: 0,
    code: 'PEN',
    namePlural: 'Peruvian nuevos soles',
  },
  PHP: {
    symbol: '₱',
    name: 'Philippine Peso',
    symbolNative: '₱',
    decimalDigits: 2,
    rounding: 0,
    code: 'PHP',
    namePlural: 'Philippine pesos',
  },
  PKR: {
    symbol: 'PKRs',
    name: 'Pakistani Rupee',
    symbolNative: '₨',
    decimalDigits: 0,
    rounding: 0,
    code: 'PKR',
    namePlural: 'Pakistani rupees',
  },
  PLN: {
    symbol: 'zł',
    name: 'Polish Zloty',
    symbolNative: 'zł',
    decimalDigits: 2,
    rounding: 0,
    code: 'PLN',
    namePlural: 'Polish zlotys',
  },
  PYG: {
    symbol: '₲',
    name: 'Paraguayan Guarani',
    symbolNative: '₲',
    decimalDigits: 0,
    rounding: 0,
    code: 'PYG',
    namePlural: 'Paraguayan guaranis',
  },
  QAR: {
    symbol: 'QR',
    name: 'Qatari Rial',
    symbolNative: 'ر.ق.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'QAR',
    namePlural: 'Qatari rials',
  },
  RON: {
    symbol: 'RON',
    name: 'Romanian Leu',
    symbolNative: 'RON',
    decimalDigits: 2,
    rounding: 0,
    code: 'RON',
    namePlural: 'Romanian lei',
  },
  RSD: {
    symbol: 'din.',
    name: 'Serbian Dinar',
    symbolNative: 'дин.',
    decimalDigits: 0,
    rounding: 0,
    code: 'RSD',
    namePlural: 'Serbian dinars',
  },
  RUB: {
    symbol: 'RUB',
    name: 'Russian Ruble',
    symbolNative: 'руб.',
    decimalDigits: 2,
    rounding: 0,
    code: 'RUB',
    namePlural: 'Russian rubles',
  },
  RWF: {
    symbol: 'RWF',
    name: 'Rwandan Franc',
    symbolNative: 'FR',
    decimalDigits: 0,
    rounding: 0,
    code: 'RWF',
    namePlural: 'Rwandan francs',
  },
  SAR: {
    symbol: 'SR',
    name: 'Saudi Riyal',
    symbolNative: 'ر.س.‏',
    decimalDigits: 2,
    rounding: 0,
    code: 'SAR',
    namePlural: 'Saudi riyals',
  },
  SDG: {
    symbol: 'SDG',
    name: 'Sudanese Pound',
    symbolNative: 'SDG',
    decimalDigits: 2,
    rounding: 0,
    code: 'SDG',
    namePlural: 'Sudanese pounds',
  },
  SEK: {
    symbol: 'kr',
    name: 'Swedish Krona',
    symbolNative: 'kr',
    decimalDigits: 2,
    rounding: 0,
    code: 'SEK',
    namePlural: 'Swedish kronor',
  },
  SGD: {
    symbol: 'S$',
    name: 'Singapore Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'SGD',
    namePlural: 'Singapore dollars',
  },
  SOS: {
    symbol: 'Ssh',
    name: 'Somali Shilling',
    symbolNative: 'Ssh',
    decimalDigits: 0,
    rounding: 0,
    code: 'SOS',
    namePlural: 'Somali shillings',
  },
  SYP: {
    symbol: 'SY£',
    name: 'Syrian Pound',
    symbolNative: 'ل.س.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'SYP',
    namePlural: 'Syrian pounds',
  },
  THB: {
    symbol: '฿',
    name: 'Thai Baht',
    symbolNative: '฿',
    decimalDigits: 2,
    rounding: 0,
    code: 'THB',
    namePlural: 'Thai baht',
  },
  TND: {
    symbol: 'DT',
    name: 'Tunisian Dinar',
    symbolNative: 'د.ت.‏',
    decimalDigits: 3,
    rounding: 0,
    code: 'TND',
    namePlural: 'Tunisian dinars',
  },
  TOP: {
    symbol: 'T$',
    name: 'Tongan Paʻanga',
    symbolNative: 'T$',
    decimalDigits: 2,
    rounding: 0,
    code: 'TOP',
    namePlural: 'Tongan paʻanga',
  },
  TRY: {
    symbol: 'TL',
    name: 'Turkish Lira',
    symbolNative: 'TL',
    decimalDigits: 2,
    rounding: 0,
    code: 'TRY',
    namePlural: 'Turkish Lira',
  },
  TTD: {
    symbol: 'TT$',
    name: 'Trinidad and Tobago Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'TTD',
    namePlural: 'Trinidad and Tobago dollars',
  },
  TWD: {
    symbol: 'NT$',
    name: 'New Taiwan Dollar',
    symbolNative: 'NT$',
    decimalDigits: 2,
    rounding: 0,
    code: 'TWD',
    namePlural: 'New Taiwan dollars',
  },
  TZS: {
    symbol: 'TSh',
    name: 'Tanzanian Shilling',
    symbolNative: 'TSh',
    decimalDigits: 0,
    rounding: 0,
    code: 'TZS',
    namePlural: 'Tanzanian shillings',
  },
  UAH: {
    symbol: '₴',
    name: 'Ukrainian Hryvnia',
    symbolNative: '₴',
    decimalDigits: 2,
    rounding: 0,
    code: 'UAH',
    namePlural: 'Ukrainian hryvnias',
  },
  UGX: {
    symbol: 'USh',
    name: 'Ugandan Shilling',
    symbolNative: 'USh',
    decimalDigits: 0,
    rounding: 0,
    code: 'UGX',
    namePlural: 'Ugandan shillings',
  },
  UYU: {
    symbol: '$U',
    name: 'Uruguayan Peso',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'UYU',
    namePlural: 'Uruguayan pesos',
  },
  UZS: {
    symbol: 'UZS',
    name: 'Uzbekistan Som',
    symbolNative: 'UZS',
    decimalDigits: 0,
    rounding: 0,
    code: 'UZS',
    namePlural: 'Uzbekistan som',
  },
  VEF: {
    symbol: 'Bs.F.',
    name: 'Venezuelan Bolívar',
    symbolNative: 'Bs.F.',
    decimalDigits: 2,
    rounding: 0,
    code: 'VEF',
    namePlural: 'Venezuelan bolívars',
  },
  VND: {
    symbol: '₫',
    name: 'Vietnamese Dong',
    symbolNative: '₫',
    decimalDigits: 0,
    rounding: 0,
    code: 'VND',
    namePlural: 'Vietnamese dong',
  },
  XAF: {
    symbol: 'FCFA',
    name: 'CFA Franc BEAC',
    symbolNative: 'FCFA',
    decimalDigits: 0,
    rounding: 0,
    code: 'XAF',
    namePlural: 'CFA francs BEAC',
  },
  XOF: {
    symbol: 'CFA',
    name: 'CFA Franc BCEAO',
    symbolNative: 'CFA',
    decimalDigits: 0,
    rounding: 0,
    code: 'XOF',
    namePlural: 'CFA francs BCEAO',
  },
  YER: {
    symbol: 'YR',
    name: 'Yemeni Rial',
    symbolNative: 'ر.ي.‏',
    decimalDigits: 0,
    rounding: 0,
    code: 'YER',
    namePlural: 'Yemeni rials',
  },
  ZAR: {
    symbol: 'R',
    name: 'South African Rand',
    symbolNative: 'R',
    decimalDigits: 2,
    rounding: 0,
    code: 'ZAR',
    namePlural: 'South African rand',
  },
  ZMK: {
    symbol: 'ZK',
    name: 'Zambian Kwacha',
    symbolNative: 'ZK',
    decimalDigits: 0,
    rounding: 0,
    code: 'ZMK',
    namePlural: 'Zambian kwachas',
  },
}
export const countries = {
  US: 'United States',
  CA: 'Canada',
  DE: 'Germany',
  AU: 'Australia',
  GB: 'UK',
  KE: 'Kenya',
  IE: 'Ireland',
  PH: 'Philippines',
  IT: 'Italy',
  HK: 'Hong Kong',
  AF: 'Afghanistan',
  AX: 'land Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'AndorrA',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, The Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: 'Cote D"Ivoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IM: 'Isle of Man',
  IL: 'Israel',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KI: 'Kiribati',
  KP: 'Korea, Democratic People"S Republic',
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Lao People"S Democratic Republic',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia, The Former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'RWANDA',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

export const MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE = 450
export const MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE_MOBILE = 2000
export const COMMENT_MAX_LENGTH = 2400
export const ASSIGNMENT_CHARACTER_LENGTH = 2000
export const MOBILE_HEADING_MAX_LENGTH = 30
export const MOBILE_CATEGORY_ITEM_HEADING_MAX_LENGTH = 80
export const INSTRUCTOR_NAME_MAX_LENGTH = 15

export const editorConfig = {
  branding: false,
  menubar: false,
  statusbar: false,
  toolbar: 'emoticons submit',
  plugins: 'emoticons',
  toolbar_location: 'bottom',
  skin: 'naked',
  content_style: 'p { margin: 0; }',
  height: '122',
  entity_encoding: 'numeric',
}

export const assignmentEditorConfig = {
  branding: false,
  menubar: false,
  statusbar: false,
  toolbar: false,
  plugins: 'paste',
  content_style: 'p { margin: 0; }',
  skin: 'naked',
  height: '240',
  entity_encoding: 'numeric',
}

export const mobileCommentEditorConfig = {
  branding: false,
  menubar: false,
  statusbar: false,
  toolbar: '',
  plugins: 'paste autoresize',
  resize: true,
  max_height: 100,
  min_height: 32,
  autoresize_bottom_margin: 0,
  paste_as_text: true,
  skin: 'naked',
  content_style:
    'body { margin: 0; min-height: auto; padding: 0.35rem 0.5rem !important; font-size: 14px;} body::before { padding: 0rem 0.5rem; color: #a0aec0 !important; } p { margin: 0; }',
  height: '32px',
  entity_encoding: 'numeric',
  placeholder: 'Write something here',
}

export const SLICED_TITLE_LENGTH = 20

// Reference for the TYPE_TO_EXTENSION constant
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// video/quicktime was not mentioned in mdn doc link mentioned above. added explicitly
// default keys added for each type of file e.g. .bin for application, .jpeg for image

export const TYPE_TO_EXTENSION = {
  application: {
    default: '',
    'x-abiword': '.abw',
    'x-freearc': '.arc',
    'vnd.amazon.ebook': '.azw',
    'octet-stream': '.bin',
    'x-bzip': '.bz',
    'x-bzip2': '.bz2',
    'x-cdf': '.cda',
    'x-csh': '.csh',
    msword: '.doc',
    'vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'vnd.ms-fontobject': '.eot',
    'epub+zip': '.epub',
    gzip: '.gz',
    'java-archive': '.jar',
    json: '.json',
    'ld+json': '.jsonld',
    'vnd.apple.installer+xml': '.mpkg',
    'vnd.oasis.opendocument.presentation': '.odp',
    'vnd.oasis.opendocument.spreadsheet': '.ods',
    'vnd.oasis.opendocument.text': '.odt',
    ogg: '.ogx',
    pdf: '.pdf',
    'x-httpd-php': '.php',
    'vnd.ms-powerpoint': '.ppt',
    'vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
    'vnd.rar': '.rar',
    rtf: '.rtf',
    'x-sh': '.sh',
    'x-shockwave-flash': '.swf',
    'x-tar': '.tar',
    'vnd.visio': '.vsd',
    'xhtml+xml': '.xhtml',
    'vnd.ms-excel': '.xls',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    xml: '.xml',
    'vnd.mozilla.xul+xml': '.xul',
    zip: '.zip',
    'x-7z-compressed': '.7z',
  },
  image: {
    default: '.jpeg',
    avif: '.avif',
    bmp: '.bmp',
    gif: '.gif',
    'vnd.microsoft.icon': '.ico',
    jpeg: '.jpeg',
    jpg: '.jpg',
    png: '.png',
    'svg+xml': '.svg',
    tiff: '.tiff',
    webp: '.webp',
  },
  video: {
    default: '.mp4',
    'x-msvideo': '.avi',
    mp4: '.mp4',
    mpeg: '.mpeg',
    ogg: '.ogv',
    mp2t: '.ts',
    webm: '.webm',
    '3gpp': '.3gp',
    '3gpp2': '.3g2',
    quicktime: '.mov',
  },
  text: {
    default: '.txt',
    css: '.css',
    csv: '.csv',
    html: '.html',
    calendar: '.ics',
    javascript: '.js',
    plain: '.txt',
  },
  audio: {
    default: '.mp3',
    midi: '.midi',
    mpeg: '.mp3',
    ogg: '.oga',
    opus: '.opus',
    wav: '.wav',
    webm: '.weba',
  },
  font: {
    default: '.otf',
    otf: '.otf',
    ttf: '.ttf',
    woff: '.woff',
    woff2: '.woff2',
  },
}

export const ALLOWED_IMAGE_FILE_TYPE_LIST = [
  'image/svg+xml',
  'image/png',
  'image/webp',
  'image/jpg',
  'image/jpeg',
]

export const assignmentSupportedFileMimes = [
  ...ALLOWED_IMAGE_FILE_TYPE_LIST,
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

export const SHOW_ASSIGNMENT_FILE_TYPE_LIST = [
  '.xlsx',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.pdf',
  ...ALLOWED_IMAGE_FILE_TYPE_LIST,
]

export const UI_PAYMENT_ELEMENT_EVENT = {
  READY: 'ready',
  PROCESSING: 'processing',
  ERROR: 'error',
  SUCCESS: 'success',
}

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  SANDBOX: 'sandbox',
  PRODUCTION: 'production',
}

export const videoPlayerControls = [
  'play-large', // The large play button in the center
  'play', // Play/pause playback
  'progress', // The progress bar and scrubber for playback and buffering
  'current-time', // The current time of playback
  'duration', // The full duration of the media
  'mute', // Toggle mute
  'volume', // Volume control
  'captions', // Toggle captions
  'settings', // Settings menu
  'pip', // Picture-in-picture (currently Safari only)
  'fullscreen', // Toggle fullscreen
]

export const videoPlayerPlaybackSpeeds = [
  0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2,
]
export const videoFileTypeMapping = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Containers
  // quicktime is not supported by mulitple browsers. Playing .mov files with mp4 type works in all browsers
  mov: 'video/mp4',
}

export const MAX_COMMENT_LENGTH = 80
