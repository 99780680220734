import Category from './Category'
import Post from './Post'

export enum CategoryTreeNodeType {
  Category = 'Category',
  Post = 'Post',
}

export default class CategoryTreeNode {
  private _nodeElement: Category | Post
  private _children: Array<CategoryTreeNode>
  private _nodeType: CategoryTreeNodeType
  private _parentNodeId: string
  private _expanded: boolean

  public static ROOT_PARENT_NODE = 'root_category'

  constructor(
    node: Category | Post,
    type: CategoryTreeNodeType,
    parent?: string | null
  ) {
    this._nodeElement = node
    this._children = []
    this._nodeType = type
    this._parentNodeId = parent || CategoryTreeNode.ROOT_PARENT_NODE
    this._expanded = true
  }

  get node(): Category | Post {
    return this._nodeElement
  }

  get id(): string {
    return this._nodeElement.id
  }

  get childs(): Array<CategoryTreeNode> {
    return this._children
  }

  set childs(children: Array<CategoryTreeNode>) {
    this._children = children
  }

  get type(): CategoryTreeNodeType {
    return this._nodeType
  }

  get parent(): string {
    return this._parentNodeId
  }

  set parent(parentNodeId: string) {
    this._parentNodeId = parentNodeId

    this.node.parentCategory =
      parentNodeId === CategoryTreeNode.ROOT_PARENT_NODE ? null : parentNodeId
  }

  get sequenceNo(): number {
    return this._nodeElement.sequenceNo
  }

  set sequenceNo(no: number) {
    this._nodeElement.sequenceNo = no
  }

  get expanded(): boolean {
    return this._expanded
  }

  set expanded(expanded: boolean) {
    this._expanded = expanded
  }

  get parentIsRoot(): boolean {
    return this.parent === CategoryTreeNode.ROOT_PARENT_NODE
  }
}
