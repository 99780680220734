import http from '../../http-common'
import BaseService from './BaseService'
import Post from '@/models/Post'

export default class PostService extends BaseService<Post> {
  protected subdomain = 'posts'

  model() {
    return Post
  }

  constructor(locationId: string) {
    super(locationId)
  }

  noOfPublishedPosts(params?: any) {
    return http
      .get(`${this.endpoint}/no-posts-published`, {
        params,
      })
      .then((resp) => resp.data)
  }
  isPostLocked(postId: string) {
    return http
      .get(`${this.endpoint}/lock-status/${postId}`)
      .then((resp) => resp.data)
  }
}
