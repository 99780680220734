import Model from './Model'

export enum PostMaterialType {
  pdf = 'pdf',
  image = 'image',
}

export default class PostMaterial extends Model {
  constructor(payload?: {
    title?: string
    type?: string
    url?: string
    postId?: string
    sequenceNo?: number
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get type(): string {
    return this._data.type
  }

  set type(type: string) {
    this._data.type = type
  }

  get url(): string {
    return this._data.url
  }

  set url(url: string) {
    this._data.url = url
  }

  get sequenceNo(): number {
    return this._data.sequenceNo
  }

  set sequenceNo(sequenceNo: number) {
    this._data.sequenceNo = sequenceNo
  }

  get postId(): string {
    return this._data.postId
  }

  set postId(postId: string) {
    this._data.postId = postId
  }
}
