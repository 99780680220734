<template>
  <div
    class="pwa-modal z-20 fixed top-0 left-0 w-full h-full"
    v-if="open || pwaTriggerInstallAlert"
  >
    <div class="w-full h-full bg-gray-900 opacity-75 transition-opacity"></div>
    <div
      class="modal-content max-w-sm mb-5 bg-white absolute transform p-4 rounded-lg w-11/12 flex flex-col items-center"
      :class="getModalPosition(browserName === 'safari' ? 'bottom' : 'center')"
    >
      <div class="flex w-full justify-end text-gray-600" @click="closeModal">
        <BaseIcon name="cross" hwClass="w-4 h-4" />
      </div>
      <img class="w-12" :src="pwaAlertData.logo || logo" />
      <div class="text-lg font-bold mt-3 mb-2">
        Install {{ pwaAlertData.name || pageTitle }}
      </div>
      <template
        v-if="
          deviceName === 'iphone' &&
          (browserName === 'chrome' || browserName === '')
        "
      >
        <p class="text-center px-2 mb-3 text-gray-600">
          To add this app to your Home Screen, open this link in Safari
        </p>
        <div
          @click="copyLinkToClipboard"
          class="w-full rounded text-center p-2 bg-gray-300 font-bold"
        >
          <span v-if="isCopied">Copied</span>
          <span v-else>Copy link to app</span>
        </div>
      </template>
      <template
        v-if="
          (deviceName === 'iphone' && browserName === 'safari') ||
          (isTablet && deviceName !== 'android' && browserName === 'safari')
        "
      >
        <p class="text-center px-2 mb-3 text-gray-600">
          Add this app to your home screen for easy access and a better
          experience.
        </p>
        <div
          class="w-full text-gray-700 text-center flex justify-center items-center pt-4 border-t"
        >
          Tap
          <span class="mx-1">
            <BaseIcon name="iosshare" />
          </span>
          then "Add to Home Screen"
        </div>
      </template>
      <template v-if="deviceName === 'android' && browserName === 'chrome'">
        <p class="text-center px-2 mb-3 text-gray-600">
          Start your course right from your phone's home screen, no download
          required
        </p>
        <div
          class="w-full text-gray-700 text-center flex justify-center items-center pt-4 border-t"
        >
          Tap
          <span class="mx-1">
            <BaseIcon name="dotsvertical" hwClass="w-5 h-5" />
          </span>
          then "Add to Home Screen"
        </div>
      </template>
      <template
        v-if="
          deviceName === 'android' &&
          (browserName === 'safari' || browserName === '')
        "
      >
        <p class="text-center px-2 mb-3 text-gray-600">
          To add this app to your Home Screen, open this link in Chrome
        </p>
        <div
          @click="copyLinkToClipboard"
          class="w-full rounded text-center p-2 bg-gray-300 font-bold"
        >
          <span v-if="isCopied">Copied</span>
          <span v-else>Copy link to app</span>
        </div>
      </template>
      <div
        v-if="browserName === 'safari' && popupPos === 'bottom'"
        class="w-5 h-5 absolute transform rotate-45 left-1/5 bg-white"
        style="bottom: -0.625rem"
      ></div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { isAppInstalled, detectDevice, detectBrowser } from '@/helper'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    pwaAlertData: {
      type: Object,
    },
    isLoggedIn: {
      type: Boolean,
    },
  },
  data() {
    return {
      open: false,
      deviceName: '',
      browserName: '',
      isCopied: false,
      popupPos: 'center',
      defaultTabletSize: 744,
    }
  },
  beforeMount() {
    this.deviceName = detectDevice()
    this.browserName = detectBrowser()
    this.checkPWACompatibility()
  },
  computed: {
    logo(): string {
      return this.$store.getters.logo
    },
    pageTitle(): string {
      return this.$store.getters.pageTitle
    },
    pwaTriggerInstallAlert(): boolean {
      return this.$store.getters.pwaTriggerInstallAlert
    },
    isTablet() {
      return window.innerHeight >= this.defaultTabletSize
    },
  },
  methods: {
    getModalPosition(pos: string) {
      const centerPosition =
        'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
      if (window.innerWidth >= this.defaultTabletSize) {
        return centerPosition
      }
      switch (pos) {
        case 'bottom':
          this.popupPos = pos
          return 'bottom-0 left-1/2 -translate-x-1/2'
        default:
          return centerPosition
      }
    },
    checkPWACompatibility() {
      if (!this.browserName || !this.deviceName || !this.isLoggedIn) {
        return
      }

      if (!isAppInstalled()) {
        this.open = true
      }
    },
    copyLinkToClipboard() {
      window.navigator.clipboard.writeText(window.location.href)
      this.isCopied = true
    },
    closeModal() {
      if (this.pwaTriggerInstallAlert) {
        this.$store.commit('SET_TRIGGER_PWA_INSTALL', false)
        return
      }
      this.open = !this.open
    },
  },
})
</script>
