import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72f7eae9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["ld", _ctx.customCss]),
      style: _normalizeStyle({ 'border-left': `1.1em solid ${_ctx.color}` })
    }, null, 6)
  ]))
}