import { TYPE_TO_EXTENSION } from './constants'
import { replaceBucketUrlWithCdnUrl } from './filter'

export async function downloadFile(givenUrl: string, title?: string) {
  await fetch(replaceBucketUrlWithCdnUrl(givenUrl), {
    method: 'GET',
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const downloadTrigger = document.createElement('a')
      downloadTrigger.href = url

      // Using last segment of url for title
      const urlPath = givenUrl.split('/')
      const titleFromUrl = urlPath.length ? urlPath[urlPath.length - 1] : ''
      downloadTrigger.download =
        title || titleFromUrl || new Date().getTime().toString()
      downloadTrigger.click()
      window.URL.revokeObjectURL(url)
    })
}

export function humanReadableFileSize(size): string {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`
}

export const get1DigitRandomNumber = (): number => {
  return Math.floor(1 + Math.random() * 9)
}

export function findExtensionFromFileType(fileType: string): string {
  if (fileType === null || fileType === undefined) return ''

  /**
   * fileType is a string of format: mainType/subType
   */
  const splitedFileType = fileType.split('/')

  const mainType = splitedFileType[0]
  const subType = splitedFileType[1]

  if (mainType === undefined || subType === undefined) {
    return ''
  }

  if (TYPE_TO_EXTENSION[mainType] === undefined) return ''
  if (TYPE_TO_EXTENSION[mainType][subType] === undefined) {
    return TYPE_TO_EXTENSION[mainType]['default']
  } else {
    return TYPE_TO_EXTENSION[mainType][subType]
  }
}
